import { ref } from 'vue'
import axios from 'axios'
import useSnackbar, { SnackbarType } from '@/composables/snackbar'

export default function useFitnessAssessments() {
  const RESOURCE = '/assessment/fitness'

  const { showSnackbar } = useSnackbar()

  const dtHeaders = [
    {
      text: 'UNIQUE KEY', value: 'uniqueKey',
    },
    {
      text: 'TITLE OF SESSION', value: 'titleOfSession',
    },
    {
      text: 'SESSION TYPE', value: 'sessionType',
    },
    {
      text: 'CATEGORY', value: 'category',
    },
    {
      text: 'LEVEL', value: 'level',
    },
    {
      text: 'FREQUENCY', value: 'frequency',
    },
    {
      text: 'MEASUREMENT', value: 'measurement',
    },
    {
      text: 'PRACTICE TYPE', value: 'practiceType',
    },
    {
      text: 'GOAL', value: 'goal',
    },
    {
      text: 'SORT NO.', value: 'sortNo',
    },
    {
      text: 'EXERCISE', value: 'exercise',
    },
    {
      text: 'EX. PRIORITY', value: 'exercisePriority',
    },
    {
      text: 'SCORING DESC.', value: 'scoringDescriptions',
    },
    {
      text: 'TIPS', value: 'tips', sortable: false,
    },
    {
      text: 'ACTIONS', value: 'actions', sortable: false, width: 100, align: 'center',
    },
  ]
  const dtSearchQuery = ref('')
  const dtExpanded = ref([])

  const blankFitnessAssessment = {
    id: '',
    uniqueKey: '',
    sessionType: '',
    titleOfSession: '',
    category: '',
    level: '',
    frequency: '',
    measurement: '',
    practiceType: '',
    goal: '',
    sortNo: '',
    exercisePriority: '',
    tips: [],
    scoringDescriptions: [],
    exercise: null,
    exerciseUniqueKey: '',
  }

  const fitnessAssessments = ref([])
  const fitnessAssessment = ref(structuredClone(blankFitnessAssessment))

  const isLoading = ref(false)

  const createFitnessAssessment = async fitnessAssessmentItem => {
    isLoading.value = true

    await axios.post(RESOURCE, fitnessAssessmentItem)
      .then(() => {
        showSnackbar('You have successfully created a new fitness assessment', SnackbarType.SUCCESS)
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to create a new fitness assessment:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  // eslint-disable-next-line no-unused-vars
  const deleteFitnessAssessment = async fitnessAssessmentItem => {
    // TODO: use API when it will be ready
  }

  const fetchFitnessAssessments = async () => {
    isLoading.value = true

    await axios.get(RESOURCE)
      .then(response => {
        fitnessAssessments.value = response.data
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to fetch fitness assessments:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const updateFitnessAssessment = async fitnessAssessmentItem => {
    isLoading.value = true

    await axios.put(RESOURCE, fitnessAssessmentItem)
      .then(() => {
        showSnackbar('You have successfully updated the fitness assessment', SnackbarType.SUCCESS)
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to update the fitness assessment:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  return {
    dtHeaders,
    dtSearchQuery,
    dtExpanded,

    blankFitnessAssessment,
    fitnessAssessments,
    fitnessAssessment,

    isLoading,

    createFitnessAssessment,
    deleteFitnessAssessment,
    fetchFitnessAssessments,
    updateFitnessAssessment,
  }
}
